import React from "react";
import tw from "twin.macro";

import { H2 } from "./Heading";
import Talk from "./Talk";

const ProgramTW = tw.div``;

const Talks = tw.ul`
  list-none
  m-0
  p-0
`;

class Program extends React.Component {
  render() {
    return (
      <ProgramTW>
        <H2>Program</H2>
        <Talks>
          <Talk topic="Zdravotní dozor na&nbsp;závodě" speaker="Filip Chaloupek, ESM Liberec" video="https://www.youtube.com/watch?v=6LVBtw71R7E&list=PLOE1Zfu-ueRA23BxM_bvbU_tdUAaJ0mT0&index=6" />
          <Talk topic="Jak dělám závody" speaker="Jan Novota, Tour de&nbsp;Femini a&nbsp;Tour de&nbsp;Zeleňák" video="https://www.youtube.com/watch?v=M7K9iKlk6uE&list=PLOE1Zfu-ueRA23BxM_bvbU_tdUAaJ0mT0&index=2" />
          <Talk topic="Jak dělám registrace online a&nbsp;v&nbsp;den závodu" speaker="Ondřej Litresits, Tour de&nbsp;Ralsko a&nbsp;Hornettlon.cz" video="https://www.youtube.com/watch?v=5Ydl4ksW2U0&list=PLOE1Zfu-ueRA23BxM_bvbU_tdUAaJ0mT0&index=4" />
          <Talk topic="Čipová časomíra, co a jak" speaker="Jiří Bulan, Irontime" video="https://www.youtube.com/watch?v=tO5E3_xJnKM&list=PLOE1Zfu-ueRA23BxM_bvbU_tdUAaJ0mT0&index=5" />
          <Talk topic="Povolení a&nbsp;schvalování" speaker="Stanislav Dvořan, Triatlon Hrádek, Běh Zámeckým parkem / Prosečským hřebenem a Silvestrovský běh" video="https://www.youtube.com/watch?v=Aqku5hwR3xY&list=PLOE1Zfu-ueRA23BxM_bvbU_tdUAaJ0mT0&index=4" />
        </Talks>
      </ProgramTW>
    )
  }
};

export default Program;
