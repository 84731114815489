import React from "react";
import tw from "twin.macro";

import Program from "./Program";

const EventTW = tw.div`
  grid
  gap-60
  sm:grid-cols-2
`;

class Event extends React.Component {
  render() {
    return (
      <EventTW>
        <Program />
      </EventTW>
    )
  }
};

export default Event;
