import React from "react";
import tw from "twin.macro";

import { H1 } from "./Heading";

const MeetUpDescriptionTW = tw.div``;

const P = tw.p`
  text-18
  max-w-leadParagraph
`;

class MeetUpDescription extends React.Component {
  render() {
    return (
      <MeetUpDescriptionTW>
        <H1>MeetUp <span>organizátorů závodů a&nbsp;sportovních akcí</span></H1>
        <P>Setkání lidí, kteří vám rozumí. Pokec s&nbsp;budoucími kamarády, kteří pro vás budou mít dobrou radu.</P>
      </MeetUpDescriptionTW>
    )
  }
};

export default MeetUpDescription;
