import React from "react"
import tw from "twin.macro"

import { H3 } from "./Heading"
import { LinkComponent } from "./Link";


type Props = {
  speaker: string;
  topic: string;
  video?: string;
};

const TalkTW = tw.li`
  max-w-talk
  py-12
  border-t
  border-white
  border-opacity-20
  last:border-b
  last:border-white
  last:border-opacity-20
`;

const Talk: React.ComponentType<Props> = ({ speaker, topic, video }) => {
  return (
    <TalkTW>
      <H3>{topic}</H3>
      <p>{speaker}</p>
      { video && (
        <LinkComponent openInNewWindow title="Záznam přednášky" to={video} />
      )}
    </TalkTW>
  )
};

export default Talk;
