import * as React from "react";
import tw from "twin.macro";

import { Layout } from "../components/Layout";
import MeetUpDescription from "../components/MeetUpDescription";
import MeetUpPlace from "../components/MeetUpPlace";
import Event from "../components/Event";

const Wrapper = tw.div`
  grid
  gap-60
  sm:gap-40
`;

const IndexPage = () => {
  return (
    <Layout>
      <Wrapper>
        <MeetUpDescription />
        <MeetUpPlace />
        <Event />
      </Wrapper>
    </Layout>
  )
};

export default IndexPage
