import React from "react";
import tw from "twin.macro";

import { LinkComponent } from "./Link";

const MeetUpPlaceTW = tw.div``;

const P = tw.p`
  text-18
  max-w-leadParagraph
`;

class MeetUpPlace extends React.Component {
  render() {
    return (
      <MeetUpPlaceTW>
        <P>Sobota 12. února 2022</P>
        <P>
          <LinkComponent
            to="https://www.google.com/maps/place/Kozlovna+Sklen%C3%ADk/@50.689884,14.5261931,15z/data=!4m2!3m1!1s0x0:0x35788dc5acb71092?sa=X&ved=2ahUKEwiDqbrqmNz0AhUhif0HHVE-A7cQ_BJ6BAg3EAU"
            title="Kozlovna Skleník, Česká Lípa"
            openInNewWindow
          />
        </P>
        <P>15:00 – 19:00</P>
        <P>Vstupné: <strong>150,-</strong></P>
        <P><small>(Občerstvení v ceně)</small></P>
      </MeetUpPlaceTW>
    )
  }
};

export default MeetUpPlace;
